import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        lang
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        logo
        banner
      }
    }
  }
`

const SEO = ({
  title,
  description,
  image,
  article = false,
  authors,
  datePublished,
}) => {
  const { pathname } = useLocation()
  const {
    site,
    site: { siteMetadata },
  } = useStaticQuery(query)

  // Destructure Site Properties
  const { buildTime } = site

  // Destructure SiteMetadata Properties
  const {
    lang,
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    logo,
    banner,
  } = siteMetadata

  // Generate SEO Data Object
  const seo = {
    lang: lang,
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`,
    canonical: pathname ? `${siteUrl}${pathname}` : null,
    image: image || `${siteUrl}/${banner}`,
    logo: `${siteUrl}/${logo}`,
  }

  // Schema.Org JSON+LD
  let schemaOrgJSONLD = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      "@id": siteUrl,
      url: siteUrl,
      name: title,
      alternateName: "",
    },
  ]
  if (article) {
    const authorsSchema = authors.map(author => {
      return {
        "@type": "Person",
        name: author.name,
      }
    })
    schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "BlogPosting",
        "@id": seo.url,
        url: seo.url,
        name: seo.title,
        alternateName: "",
        headline: seo.title,
        image: {
          "@type": "ImageObject",
          url: seo.image,
        },
        description: seo.description,
        datePublished: datePublished || buildTime,
        dateModified: buildTime,
        author: authorsSchema,
        publisher: {
          "@type": "Organization",
          name: "Do Not Research",
          logo: {
            "@type": "ImageObject",
            url: seo.logo,
          },
        },
        isPartOf: siteUrl,
        mainEntityOfPage: {
          "@type": "WebSite",
          "@id": siteUrl,
        },
      },
    ]
  }

  // Return React-Helmet
  return (
    <Helmet
      htmlAttributes={{
        lang: seo.lang,
      }}
      title={seo.title}
      titleTemplate={titleTemplate}
      link={
        seo.canonical
          ? [
              {
                rel: "canonical",
                href: seo.canonical,
              },
            ]
          : []
      }
    >
      {/* apple and application tags */}
      <meta name="apple-mobile-web-app-title" content={defaultTitle} />
      <meta name="application-name" content={defaultTitle} />
      {/* ld+json data */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      {/* Meta Tags */}
      {seo.description && <meta name="description" content={seo.description} />}
      {/* Open Graph Tags */}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      <meta property="og:type" content="website" />
      <meta property="og:image" content={seo.image} />
      <meta property="og:width" content="1024" />
      <meta property="og:height" content="373" />
      {/* Twitter Open Graph Tags */}
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  )
}

export default SEO
