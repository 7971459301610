import React, { Fragment } from "react"
import classnames from "classnames"
import capitalize from "lodash.capitalize"

/* Import Global Components */
import SEO from "~components/seo"
import Footer from "~components/footer"

/* Import Local Styles */
import "./page.scss"

const Page = ({
  title = "",
  description,
  article = false,
  authors,
  datePublished,
  children,
  image,
  className
}) => (
  <Fragment>
    <SEO
      title={title.split(" ").map(capitalize).join(" ")}
      description={description ? description : null}
      article={article}
      authors={authors}
      datePublished={datePublished}
      image={image}
      className={className}
    />
    <main className={classnames("page", title, className)}>{children}</main>
    <Footer />
  </Fragment>
)

export default Page
