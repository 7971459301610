import React from "react"

/* Import Global Components */
import Link from "~components/link"

/* Import Local Styles */
import "./footer.scss"

const Footer = () => {
  return (
    <footer>
      <ul>
        <li>
          <Link to="/">Return to Index</Link>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
